import buttons from './buttons'

export default {
  // ? ==========================
  // ? ====  Locaition Page  ====
  // ? ==========================

  locationsPage: {
    '.logo': {
      maxWidth: ['175px', '', '200px', '300px']
    }
    // '.CTAS .CTAButton': {
    //   border: 'solid 2px white',
    //   fontFamily: 'Gochi Hand',
    // },
  },

  // ? ==========================
  // ? =======  Layout  =========
  // ? ==========================

  navigation: {
    // ? === nav container ===
    '.container': {
      padding: '1rem'
    },

    // ? === nav container scrolled ===
    '.containerScrolled': {
      backgroundColor: 'dark'
    },

    // ? === nav logo ===
    '.logo': {
      img: {
        maxHeight: ['110px', '', '200px']
      }
    },

    '.logoScrolled': {
      img: {}
    },

    // ? === hamburger ===
    '.hamburger div, .hamburgerOpen div': {
      backgroundColor: 'light',
      borderRadius: '0px',
      height: '2px'
    },

    // ? === Desktop nav menu ===
    '.smallNavMenu': {
      '.navItem': {
        a: {
          color: 'light',
          fontSize: '1.25rem',
          color: 'white',
          ':hover': {
            color: 'grey'
          }
        }
      },
      '> .online-order': {
        a: {
          margin: '0 1rem',
          variant: 'buttons.secondary',
          fontSize: ['1rem', '1rem', '1rem']
        }
      }
    },

    // ? === Drawer nav menu ===
    '.navMenuOpen': {
      padding: ['2.5rem', '', '', '2rem'],
      backgroundColor: 'dark',
      minWidth: '275px',
      '.navItem': {
        color: 'light',
        paddingBottom: '0.5rem',
        borderBottom: '1px solid #757575',
        width: '100%'
      },

      '.navItemDropdown': {
        width: '100%'
      },
      '.navItemDropdownMenu': {
        backgroundColor: 'transparent'
      }
    },

    // ? === Drawer nav menu ===

    '.navItem': {
      'a:hover': {
        color: 'primary'
      }
    },

    // ? === Drawer nav bg block ===

    '.navBlock': {
      backgroundColor: 'rgba(0,0,0,0.8)'
    },

    // ? === Widgets ===

    '.socialContainer': {
      svg: {
        width: '25px',
        height: '25px',
        path: {
          fill: 'light'
        }
      }
    }
  },

  footer: {
    borderTop: 'solid 3px',
    borderColor: 'primary',
    backgroundColor: 'dark',
    '.contactColumn': {
      border: 'none !important'
    },
    '.aboutText': {
      marginBottom: '1.5rem'
    },

    '.multiButtonContainer': {
      justifyContent: ['center', '', '', 'flex-start'],
      flexDirection: ['', '', '', 'column'],
      a: {
        padding: ['', '', '', '0.75rem 0.5rem 0.75rem 0rem']
      }
    },

    '.contactDetails-container': {
      marginBottom: '1rem'
    },
    '.sectionHeading': {
      fontWeight: 'normal',
      fontSize: '2.5rem',
      color: 'primary'
    },
    '.locationSwitcherV1': {
      marginTop: '1rem',
      '.button': {
        variant: 'buttons.primary',
        flexDirection: 'row',
        svg: {
          marginRight: '0.5rem'
        }
      }
    },
    '.footerAboutButton .ctaButton': {
      variant: 'buttons.secondary',
      borderRadius: '0px'
    }
  },

  ctaWidget: {
    zIndex: '999',
    color: 'light'
  },

  // ? ==========================
  // ? ======  reuseables  ========
  // ? ==========================

  pageHero: {
    alignItems: 'flex-end',
    // borderRadius: '0px 0px 100px 0px',
    clipPath: 'polygon(100% 0, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0 0)',
    height: ['60vh', '50vh', '', '75vh'],
    '.content': {
      h1: { marginBottom: '4rem' }
    }
  },

  sideBySide1: {
    // backgroundColor: '#292929',
    padding: ['1rem'],
    '.lazyload-wrapper': {
      width: ['', '', '55%']
    },
    '.image': {
      borderRadius: ['', '', '500px 0px 0px 500px'],
      height: ['', '', '', '75vh']
    },

    '.title': {
      borderBottom: '3px solid',
      borderColor: 'grey',
      paddingBottom: '1rem',
      fontSize: ['2.5rem', '', '', '3rem'],
      textTransform: 'uppercase',
      fontWeight: 'normal',
      color: 'primary'
    },
    '.subtitle': {
      fontWeight: 'normal',
      fontSize: ['1.75rem', '', '', '2rem']
    },
    '.content': {
      width: ['', '', '45%'],
      textAlign: 'left',
      alignItems: 'flex-start',
      padding: ['1rem', '2rem', '3rem', '', '4rem']
    },
    '.text': {
      color: 'textSecondary',
      fontSize: ['1rem', '1.25rem', '1.25rem'],
      lineHeight: '1.5',
      marginBottom: '2rem'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },
  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.image': {
      borderRadius: ['', '', '0px 500px 500px 0px'],
      height: ['', '', '', '75vh']
    }
  },

  // ? ==========================
  // ? ======  Homepage  ========
  // ? ==========================

  homepageHero: {
    '.title': {
      borderBottom: '2px solid',
      borderLeft: 'none',
      paddingLeft: '0rem',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      letterSpacing: '-3px'
    },
    '.subtitle': {
      margin: '0rem',
      fontSize: ['1.5rem', '', '3rem'],
      fontWeight: 'normal',
      textTransform: 'uppercase',
      letterSpacing: '-3px'
    },
    '.section': {
      margin: '1rem'
    }
  },

  homepageHeroShout: {},

  homepageOnlineOrderingTitle: {
    padding: ['3rem 1rem', '3rem'],
    // backgroundColor: '#292929',
    display: 'flex',
    flexDirection: 'column',
    alignItems: ['center', '', 'flex-end'],
    '.title': {
      color: 'primary',
      fontSize: ['3rem', '', '5rem'],
      paddingBottom: '0.5rem',
      fontWeight: '300',
      borderBottom: '3px solid',
      borderColor: 'grey',
      width: 'fit-content',
      textTransform: 'uppercase'
    },
    '.subtitle': {
      width: 'fit-content',
      opacity: '0.8',
      fontSize: ['1.75rem', '', '3rem'],
      fontWeight: 'normal'
    }
  },

  shoutSection: {
    padding: ['1rem'],
    // marginBottom: '3rem',
    '.imageContainer ': {
      // width: ['', '50%', '60%'],
      order: ['2', '', '1'],
      height: ['', '', '', '75vh']
    },
    '.image': {
      borderRadius: ['0px', '', '0px 500px 500px 0px'],
      height: ['', '', '', '75vh']
    },
    '.content': {
      color: 'white',
      textAlign: 'left',
      alignItems: 'flex-start',
      padding: ['1rem', '2rem', '3rem', '3rem'],
      order: ['1', '', '2']
    },
    '.date, .title, .text': {
      textAlign: 'left',
      fontWeight: 'normal',
      margin: '0rem'
    },

    '.title': {
      display: 'flex',
      flexDirection: 'column',
      '::before': {
        content: "'Socialize'",
        borderBottom: '3px solid',
        borderColor: 'grey',
        paddingBottom: '1rem',
        fontSize: ['', '', '', '3rem'],
        textTransform: 'uppercase',
        marginBottom: '1rem',
        color: 'primary'
      },
      color: 'light',
      paddingBottom: '1rem',
      fontSize: ['', '', '', '1.75rem'],
      textTransform: 'uppercase',
      width: 'fit-content',
      margin: '0rem'
    },

    '.date': {
      opacity: '0.8',
      order: '5',
      fontSize: '1rem'
    },

    '.text': {
      fontSize: ['1rem', '1.25rem', '1.75rem'],
      lineHeight: ['1.5', '', '', '1.5'],
      color: 'textSecondary',
      marginBottom: '1rem',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      letterSpacing: '1px'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },
  homepageMenu: {
    variant: 'customVariants.sideBySide2'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageSlider: {},

  homepageContentBoxes: {},

  homepageContentSection: {
    color: 'white',
    padding: '20vh 1rem',
    '.title': {
      fontWeight: 'normal',
      color: 'primary',
      order: '1'
    },
    '.subtitle': {
      fontWeight: 'normal',
      order: '2'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      order: '3'
    }
  },

  // ? ==========================
  // ? =====  About Page  =======
  // ? ==========================

  aboutSection: {},

  // ? ==========================
  // ? ======  Menu Page  =======
  // ? ==========================

  menu: {
    '.dropdownContainer': {
      display: 'none'
    },

    '#menuTab': {
      fontFamily: 'heading',
      padding: '0.5rem 1rem 1rem',
      fontSize: '1.5rem',
      fontWeight: 'normal'
    },

    marginY: '2rem',
    border: 'solid 1px',
    borderColor: '799d3d',
    '*': {
      boxShadow: 'none'
    },
    '.menuLogo': {
      marginBottom: '2rem'
    },
    '.menuSectionTitle': {
      color: 'primary',
      fontWeight: 'normal',
      borderBottom: 'solid 2px',
      borderColor: 'primary',
      paddingBottom: '0.5rem',
      fontSize: ['1.75rem', '2rem', '2.5rem'],
      textAlign: 'left',
      textTransform: 'uppercase',
      width: '95%',
      margin: ['0rem 0rem 1rem 0.5rem', '', '0rem 1.5rem 1.5rem']
    },
    '.menuSectionDescription': {
      marginBottom: '1rem',
      textAlign: 'left',
      padding: ['1rem', '', '1rem 1.75rem'],
      color: 'primary'
    },
    '.menuItemContainer': {
      margin: '0rem'
    },
    '.menuItemName': {
      color: 'light',
      textTransform: 'uppercase',
      // fontWeight: 'bold',
      fontSize: ['1.25rem', '1.5rem', '1.75rem'],
      fontWeight: 'normal',
      margin: '0rem',
      fontFamily: 'heading'
    },
    '.menuItemContentContainer': {
      padding: '0rem',
      margin: '0rem'
    },
    '.menuItemPrice': {
      color: 'primary',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontSize: ['1rem', '1.15rem', '1.25rem'],
      fontFamily: 'heading'
    },
    '.menuItemDescription': {
      fontSize: '1rem',
      fontWeight: '600',
      opacity: '0.6'
    },

    '.itemVariantsContainer': {
      padding: '0.75rem 0rem 0.25rem'
    },

    '.menuItemPriceLabel': {
      fontSize: '0.9rem'
    },

    '.menuItemPriceVariants': {
      color: 'primary',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontSize: ['1rem', '1.15rem', '1.25rem'],
      fontFamily: 'heading'
    },

    '.section-cell': {
      width: ['100%', 'calc(50% - (0.5rem * 2))', 'calc(33.333% - (0.5rem * 2))'],
      borderRadius: '0px',
      backgroundColor: 'transparent',
      border: 'none',
      position: 'relative'
    },
    '.menuCell': {
      borderRadius: '0px'
    },

    '.cellImage': {
      borderRadius: '0px'
    },

    '.cellName': {
      position: 'absolute',
      transform: 'translate(-50%,-50%)',
      textAlign: 'center',
      width: '100%',
      top: '50%',
      left: '50%',
      color: 'light',
      textTransform: 'uppercase',
      fontSize: ['1rem', '1.15rem', '1.75rem'],
      margin: '0rem',
      fontFamily: 'heading',
      padding: '0.5rem'
    }
  },

  // ? ==========================
  // ? =====  Gallery Page  =====
  // ? ==========================

  gallery: {},

  // ? ==========================
  // ? =====  Events Page  ======
  // ? ==========================

  events: {},

  // ? ==========================
  // ? =====  Contact Page  =====
  // ? ==========================

  locationsMap: {},

  contactForm: {
    backgroundColor: 'dark',
    color: 'white',
    '.inputField, .textField ': {
      borderColor: 'primary',
      borderRadius: '0px',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      backgroundColor: 'black',
      padding: '0.25rem 1rem 1rem'
    }
  }
}

export default {
  primary: {
    // backgroundColor: 'transparent',
    color: 'primary',
    fontFamily: 'heading',
    fontWeight: 'normal',
    border: 'solid 2px',
    borderColor: 'primary',
    padding: '0.5rem 1.5rem 1rem',
    fontSize: ['1.5rem', '1.75rem'],
    transition: 'all ease-in-out 0.6s',
    position: 'relative',
    backgroundColor: 'dark',
    '::before': {
      border: 'solid 2px',
      content: "''",
      height: 'calc(100% + 10px)',
      width: 'calc(100% + 10px)',
      position: 'absolute',
      top: '-7.5px',
      left: '-7.5px',
      zIndex: '-1'
    },
    ':hover': {
      backgroundColor: 'primary',
      color: 'white',
      transform: 'skewX(-10deg)'
      // borderRadius: '20px',
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    color: 'primary',
    fontFamily: 'heading',
    fontWeight: 'normal',
    border: 'solid 2px',
    borderColor: 'primary',
    padding: '0.5rem 1.5rem 1rem',
    fontSize: ['1.5rem', '1.75rem'],
    transition: 'all ease-in-out 0.6s',
    position: 'relative',
    '::before': {
      border: 'solid 2px',
      content: "''",
      height: 'calc(100% + 10px)',
      width: 'calc(100% + 10px)',
      position: 'absolute',
      top: '-7.5px',
      left: '-7.5px',
      zIndex: '-1'
    },
    ':hover': {
      backgroundColor: 'primary',
      color: 'white',
      transform: 'skewX(-10deg)'
      // borderRadius: '20px',
    }
  }
}

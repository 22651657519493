export default {
  text: 'white',
  textSecondary: '#c17b50',
  primary: '#cc9a78',
  secondary: '#463124',
  background: '#1d2020',
  backgroundSecondary: '#6c9d2a',
  light: '#FFF',
  dark: '#272727'
}

export default {
  multiLocationsV1: {
    container: {
      minHeight: '100vh',
      width: '100vw',
      position: 'relative',
      flexDirection: 'column',
      justifyContent: 'center',
      a: {
        textDecoration: 'none;'
      }
    },
    logo: {
      position: ['absolute'],
      display: ['flex'],
      justifyContent: 'center',
      top: ['50%', '', '', '0rem'],
      left: ['50%', '', '', '0rem'],
      transform: ['translate(-50%,-50%)', '', '', 'unset'],
      width: '100%',
      backgroundColor: ['rgb(0 0 0 / 58%)', '', '', 'black'],
      borderBottom: ['', '', '', '1px grey solid'],
      img: {
        maxWidth: '100%',
        maxHeight: ['65px', '75px', '', '100px']
      }
    },
    locationsContainer: {
      flexDirection: ['column', '', '', 'row'],
      flexGrow: '1',
      flexWrap: ['wrap', '', '', 'nowrap'],
      '> div:nth-of-type(1)': {
        borderRight: ['', '', '', '1px solid grey']
      }
    },
    location: {
      flexGrow: '1',
      padding: ['0.75rem 0.5rem', '', '', '1.5rem'],
      justifyContent: 'center',
      alignItems: ['center', '', '', 'flex-end'],
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      transition: 'all ease-in-out 2s',
      ':hover': {
        backgroundPosition: ['right bottom', '', '', 'top left']
      }
    },
    content: {
      textAlign: 'center',
      flexDirection: 'column'
    },
    locationTitle: {
      color: 'light',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontFamily: 'heading',
      fontSize: ['1.5rem', '2rem'],
      width: 'fit-content',
      margin: '0rem auto 1rem',
      borderBottom: '3px solid',
      borderColor: 'secondary'
    },
    CTAS: {
      flexWrap: 'wrap',
      justifyContent: 'center'
    },

    CTAButton: {
      variant: 'buttons.primary',
      display: 'flex',
      width: 'calc(50% - 0.5rem)',
      margin: '0.25rem',
      padding: '0.5rem 1rem',
      svg: {
        marginRight: '5px'
      }
    },
    enterSiteButton: {
      variant: 'buttons.primary',
      fontSize: '1rem',
      marginTop: '0.5rem',
      minWidth: '200px',
      padding: '0.5rem 1rem'
    }
  }
}
